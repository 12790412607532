import { Document, Packer, Paragraph, TextRun, HeadingLevel } from "docx";
import { saveAs } from "file-saver";

const generateDocx = (tour) => {
	// Helper function for creating styled text
	const createText = (text, options = {}) =>
		new TextRun({ text, size: 27, ...options });

	// Helper function for creating paragraphs
	const createParagraph = (content, style) =>
		new Paragraph({ children: content, heading: style });

	// Document creation
	const doc = new Document({
		sections: [
			{
				properties: {},
				children: [
					// Header
					// createParagraph(
					// 	[createText("Art of Bicycle Trips", { bold: true, size: 36 })],
					// 	HeadingLevel.TITLE
					// ),
					// createParagraph([
					// 	createText("www.artofbicycletrips.com | contact@artofbicycletrips.com", {
					// 		italics: true,
					// 		size: 24,
					// 	}),
					// ]),

					// Tour Title
					createParagraph(
						[createText(tour.title, { bold: true, size: 48, color: "#EA5E48" })],
						HeadingLevel.HEADING_1
					),
					createParagraph([]),
					createParagraph([]),
					// Tour Quick Facts
					createParagraph(
						[createText("Quick Facts", { bold: true, size: 36, color: "#EA5E48" })],
						HeadingLevel.HEADING_2
					),
					createParagraph([]),
					createParagraph([
						createText("Duration: ", { bold: true }),
						createText(tour.duration),
					]),
					createParagraph([
						createText("Tour Start: ", { bold: true }),
						createText(tour.quickfacts.start),
					]),
					createParagraph([
						createText("Tour End: ", { bold: true }),
						createText(tour.quickfacts.finish),
					]),
					createParagraph([
						createText("Lodging: ", { bold: true }),
						createText(tour.quickfacts.lodging),
					]),
					createParagraph([
						createText("Avg. dist. cycled: ", { bold: true }),
						createText(tour.quickfacts.avgDistance),
					]),
					createParagraph([
						createText("Level: ", { bold: true }),
						createText(tour.quickfacts.level.split("_").join(" to ")),
					]),
					...(tour.quickfacts.flyin
						? [
								createParagraph([
									createText("Fly-In: ", { bold: true }),
									createText(tour.quickfacts.flyin),
								]),
						  ]
						: []),
					...(tour.quickfacts.flyout
						? [
								createParagraph([
									createText("Fly-In: ", { bold: true }),
									createText(tour.quickfacts.flyout),
								]),
						  ]
						: []),
					createParagraph([]),
					createParagraph([]),
					// Highlights
					createParagraph(
						[createText("Highlights", { bold: true, size: 36, color: "#EA5E48" })],
						HeadingLevel.HEADING_2
					),
					createParagraph([]),
					...tour.highlight.map((node) =>
						createParagraph([createText(`- ${node.desc}`)])
					),
					createParagraph([]),
					createParagraph([]),
					// Overview
					createParagraph(
						[createText("Overview", { bold: true, size: 36, color: "#EA5E48" })],
						HeadingLevel.HEADING_2
					),
					createParagraph([]),
					createParagraph([createText(tour.overview)]),
					createParagraph([]),
					createParagraph([]),
					// Itinerary
					createParagraph(
						[
							createText("Detailed Itinerary", {
								bold: true,
								size: 36,
								color: "#EA5E48",
							}),
						],
						HeadingLevel.HEADING_2
					),
					createParagraph([]),
					...tour.itinerary.flatMap((node) => [
						createParagraph([
							createText(`Day ${node.day} - ${node.place}`, { bold: true }),
						]),
						createParagraph([]),
						createParagraph([createText(node.desc)]),
						createParagraph([]),
						createParagraph([
							createText("Meals: ", { bold: true }),
							createText(node.meals),
						]),
						...(node.cycDistance
							? [
									createParagraph([
										createText("Cycling Distance: ", { bold: true }),
										createText(node.cycDistance),
									]),
							  ]
							: []),
						...(node.hotelUrl
							? [
									createParagraph([
										createText("Accommodation: ", { bold: true }),
										createText(node.hotel),
									]),
							  ]
							: []),
						createParagraph([]), // Adds an empty paragraph to create a gap between days
					]),
					createParagraph([]),

					// What to Expect
					...(tour.straight
						? [
								createParagraph(
									[
										createText("What to Expect", {
											bold: true,
											size: 36,
											color: "#EA5E48",
										}),
									],
									HeadingLevel.HEADING_2
								),

								// Add a gap (blank paragraph)
								createParagraph([]),

								// Arrival Description
								createParagraph([createText(tour.straight)]),
						  ]
						: []),
					// Arrival/Departure
					createParagraph([]),
					createParagraph([]),
					...(tour.arrival
						? [
								// Arrival & Departure Header
								createParagraph(
									[
										createText("Arrival & Departure Details", {
											bold: true,
											size: 36,
											color: "#EA5E48",
										}),
									],
									HeadingLevel.HEADING_2
								),

								// Add a gap (blank paragraph)
								createParagraph([]),

								// Arrival Description
								createParagraph([createText(tour.arrival)]),
						  ]
						: []),

					createParagraph([]),
					createParagraph([]),
					// Weather
					...(tour.weather
						? [
								createParagraph(
									[
										createText("Weather", {
											bold: true,
											size: 36,
											color: "#EA5E48",
										}),
									],
									HeadingLevel.HEADING_2
								),

								// Add a gap (blank paragraph)
								createParagraph([]),

								// Arrival Description
								createParagraph([createText(tour.weather)]),
						  ]
						: []),

					createParagraph([]),
					createParagraph([]),
					// Packing List
					createParagraph(
						[createText("What to Pack", { bold: true, size: 36, color: "#EA5E48" })],
						HeadingLevel.HEADING_2
					),
					createParagraph([]),
					// Introduction
					createParagraph([
						createText(
							"Packing smart is essential for a safe and enjoyable cycling adventure. From essential biking gear to clothing and travel necessities, this guide ensures you're prepared for any terrain or climate."
						),
					]),
					createParagraph([]),
					// Recommended Bicycling Clothes & Gear
					createParagraph([
						createText("Recommended Bicycling Clothes & Gear", {
							bold: true,
						}),
					]),
					createParagraph([]),
					createParagraph([
						createText("• Bicycling shoes or stiff-soled sneakers."),
					]),
					createParagraph([createText("• Padded cycling shorts.")]),
					createParagraph([
						createText(
							"• Short-fingered cycling gloves (personal gloves are ideal)."
						),
					]),
					createParagraph([
						createText(
							"• Brightly colored cycling jerseys or synthetic T-shirts for visibility."
						),
					]),
					createParagraph([createText("• Synthetic cycling socks.")]),
					createParagraph([createText("• Sunglasses with UV protection.")]),
					createParagraph([
						createText("• Personal helmet (recommended even though provided)."),
					]),
					createParagraph([
						createText("• Personal saddle (optional for added comfort)."),
					]),
					createParagraph([createText("• Rain jacket or windbreaker.")]),
					createParagraph([
						createText("• Cycling water bottle (to reduce plastic waste)."),
					]),
					createParagraph([]),
					createParagraph([
						createText(
							"Note: Helmets, gloves, and water bottles are personal items, so bringing your own is recommended for maximum comfort and hygiene.",
							{ italics: true }
						),
					]),
					createParagraph([]),
					// Clothing
					createParagraph([createText("Clothing", { bold: true })]),
					createParagraph([]),
					createParagraph([
						createText("• Light, breathable fabrics like cotton or linen."),
					]),
					createParagraph([
						createText("• Full-sleeved clothing for sun protection."),
					]),
					createParagraph([
						createText(
							"• Warm layers for chilly mornings and evenings (if traveling in winter)."
						),
					]),
					createParagraph([
						createText("• Comfortable footwear for non-cycling time."),
					]),
					createParagraph([
						createText(
							"• Bring a compact rain jacket in case of sudden weather changes."
						),
					]),
					createParagraph([
						createText(
							"• Ethnic or modest clothing for exploring towns and temples."
						),
					]),
					createParagraph([]),
					// Other Necessities
					createParagraph([createText("Other Necessities", { bold: true })]),
					createParagraph([]),
					createParagraph([
						createText(
							"• Toiletries: Basic hygiene essentials, including insect/mosquito repellent and sunscreen."
						),
					]),
					createParagraph([createText("• Hand sanitizer and wet wipes.")]),
					createParagraph([createText("• Plug socket adaptor.")]),
					createParagraph([
						createText("• Binoculars for wildlife spotting or desert landscapes."),
					]),
					createParagraph([]),
					// Pro Tips
					createParagraph([createText("Pro Tips", { bold: true })]),
					createParagraph([]),
					createParagraph([
						createText(
							"• Pack light but plan for the varying temperatures: warm days, chilly evenings, and desert winds."
						),
					]),
					createParagraph([
						createText("• Use brightly colored clothing for visibility on roads."),
					]),
					createParagraph([createText("• Pack light to leave room for shopping.")]),
					createParagraph([]),
					// Luggage
					createParagraph([createText("Luggage", { bold: true })]),
					createParagraph([]),
					createParagraph([
						createText(
							"Please limit your luggage to one soft-sided medium-sized suitcase and one carry-on bag. Mark your luggage with your name, address, and phone number."
						),
					]),
					createParagraph([]),
					createParagraph([]),
					// Visa Information

					// Section Header
					createParagraph(
						[
							createText("Visa Information", {
								bold: true,
								size: 36,
								color: "#EA5E48",
							}),
						],
						HeadingLevel.HEADING_2
					),
					createParagraph([]),
					// Introductory Content
					createParagraph([
						createText(
							"Before venturing on your adventure, make sure you're equipped with essential travel advice, including security updates, local laws, and crucial passport and visa information. Start by visiting the official government website of your home country for accurate and reliable guidance."
						),
					]),
					createParagraph([]),
					// UK Residents
					createParagraph([
						createText("• UK Residents: ", { bold: true }),
						createText("Stay informed by reviewing the "),
						createText(
							"Foreign, Commonwealth & Development Office (FCDO) Travel Advice. ",
							{ color: "#0000EE", underline: true }
						),
						createText("(https://www.gov.uk/foreign-travel-advice)", {
							italic: true,
							color: "#0000EE",
						}),
					]),
					createParagraph([]),
					// US Residents
					createParagraph([
						createText("• US Residents: ", { bold: true }),
						createText("Access vital travel tips and updates through the "),
						createText("US Department of State Travel Advice. ", {
							color: "#0000EE",
							underline: true,
						}),
						createText(
							"(https://travel.state.gov/content/travel/en/international-travel/International-Travel-Country-Information-Pages.html)",
							{ italic: true, color: "#0000EE" }
						),
					]),
					createParagraph([]),
					// Australian Residents
					createParagraph([
						createText("• Australian Residents: ", { bold: true }),
						createText("Plan ahead with the latest updates on the "),
						createText("Smart Traveller website. ", {
							color: "#0000EE",
							underline: true,
						}),
						createText("(https://www.smartraveller.gov.au/)", {
							italic: true,
							color: "#0000EE",
						}),
					]),
					createParagraph([]),
					// Canadian Residents
					createParagraph([
						createText("• Canadian Residents: ", { bold: true }),
						createText("Find comprehensive travel and visa guidance on the "),
						createText("Government of Canada's Travel Advice and Advisories. ", {
							color: "#0000EE",
							underline: true,
						}),
						createText("(https://travel.gc.ca/travelling/advisories)", {
							italic: true,
							color: "#0000EE",
						}),
					]),
					createParagraph([]),
					// Residents of Other Countries
					createParagraph([
						createText("• Residents of Other Countries: ", {
							bold: true,
						}),
						createText(
							"Check with your government or local consulate for tailored travel advice and requirements."
						),
					]),
					createParagraph([]),
					// Closing Note
					createParagraph([
						createText("Note: ", { bold: true }),
						createText(
							"Passport and visa regulations can change at any time. Stay proactive by checking for the latest updates to ensure a smooth and stress-free journey."
						),
					]),

					createParagraph([]), // Adds an empty paragraph to create a gap between days
					createParagraph([]), // Adds an empty paragraph to create a gap between days
					// Travel Insurance

					// Section Header
					createParagraph(
						[
							createText("Travel Insurance", {
								bold: true,
								size: 36,
								color: "#EA5E48",
							}),
						],
						HeadingLevel.HEADING_2
					),
					createParagraph([]),
					// Introductory Content
					createParagraph([
						createText(
							"It is a mandatory requirement to purchase adequate travel insurance in order to participate in our tours and protect yourself from unforeseen events. Our experience shows that guests who purchase travel insurance—including coverage for trip cancellation, interruption, delay, baggage damage, loss or theft, personal accident, death and repatriation costs, medical and emergency expenses (including Covid-19), and personal liability—travel with greater peace of mind."
						),
					]),
					createParagraph([]),
					createParagraph([
						createText(
							"Can I use my credit card insurance? Yes, as long as it meets our requirements and provides adequate coverage. Ensure you have an emergency contact number and policy number available."
						),
					]),
					createParagraph([]),
					createParagraph([
						createText(
							"When should I buy travel insurance? Purchase travel insurance as soon as you've paid in full or booked your flights."
						),
					]),
					createParagraph([]),
					createParagraph([
						createText(
							"Is proof of travel insurance required? Yes. You must provide your insurer's name, policy number, and emergency contact via our reservation form. This is mandatory. If you cannot provide proof, you’ll need to purchase insurance before traveling with us."
						),
					]),
					createParagraph([]),

					createParagraph([
						createText(
							"Does Art of Bicycle Trips sell travel insurance? We do not sell travel insurance directly, as we believe our customers should have the freedom to choose a policy that best suits their needs. However, we recommend "
						),
						createText("Insured Nomads ", {
							color: "#0000EE",
							underline: true,
						}),
						createText("(https://insurednomads.com/?ref=pankaj)", {
							italic: true,
							color: "#0000EE",
						}),
						createText(" as a great starting point."),
					]),
					createParagraph([]),
					createParagraph([]),
					// FAQ Information
					createParagraph(
						[
							createText("Frequently Asked Questions", {
								bold: true,
								size: 36,
								color: "#EA5E48",
							}),
						],
						HeadingLevel.HEADING_2
					),
					createParagraph([]),
					...tour.faq.flatMap((faq) => [
						createParagraph([createText(faq.question, { bold: true })]),
						createParagraph([createText(faq.answer)]),
						createParagraph([]), // Adds an empty paragraph to create a gap between days
					]),
					...tour.faqss.faq.flatMap((faq) => [
						createParagraph([createText(faq.question, { bold: true })]),
						createParagraph([createText(faq.answer)]),
						createParagraph([]), // Adds an empty paragraph to create a gap between days
					]),
				],
			},
		],
	});

	// Generate and download the DOCX file
	Packer.toBlob(doc).then((blob) => {
		saveAs(blob, `${tour.title}.docx`);
	});
};

export default generateDocx;
