import React from "react";
import Slider from "react-slick";
import { GatsbyImage } from "gatsby-plugin-image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HotSlider = ({ slides }) => {
	const CustomPrevArrow = ({ onClick }) => {
		return (
			<button
				className="absolute left-0 z-10 p-2 text-white  rounded-full shadow-lg top-1/2 -translate-y-1/2"
				onClick={onClick}
			>
				◀
			</button>
		);
	};

	const CustomNextArrow = ({ onClick }) => {
		return (
			<button
				className="absolute right-0 z-10 p-2 text-white  rounded-full shadow-lg top-1/2 -translate-y-1/2 "
				onClick={onClick}
			>
				▶
			</button>
		);
	};
	const settings = {
		dots: false,
		infinite: true,
		speed: 1000, // Adjust the speed as needed
		autoplay: true, // Enable automatic slideshow
		autoplaySpeed: 4000, // Set the time between slides in milliseconds
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		fade: true,
		prevArrow: <CustomPrevArrow />,
		nextArrow: <CustomNextArrow />,
	};
	return (
		<div className="mb-6">
			<Slider {...settings}>
				{slides.map((slide) => {
					return (
						<div key={slide.id}>
							<GatsbyImage
								className="rounded-lg transform"
								image={slide.localFile.childImageSharp.gatsbyImageData}
								alt={slide.name.split("-").join(" ").split(".")[0]}
								// alt={slide.alternativeText}
								loading="lazy"
							/>
						</div>
					);
				})}
			</Slider>
		</div>
	);
};

export default HotSlider;
